// TODO: Include style themes here

/* Breakpoints */
$mobile: 360px;
$tablet: 768px;
$laptop: 1366px;
$desktop: 1920px;

/* Height of the footer */
$footerHeight: 111px;

/* New Distilled Colors */

// BASES
$white: rgba(255, 255, 255, 1);
$parioLightBlue: rgb(249, 250, 251);
$parioBlue: rgba(72, 110, 131, 1);

// GRAYS
$gray900: rgba(16, 24, 40, 1);
$gray800: rgba(29, 41, 57, 1);
$gray700: rgba(52, 64, 84, 1);
$gray600: rgba(71, 84, 103, 1);
$gray500: rgb(102, 112, 133);
$gray300: rgb(208, 213, 221);
$gray400: rgb(152, 162, 179);

$grey200: rgb(234, 236, 240);
$gray100: rgba(242, 244, 247, 1);
$gray50: rgba(249, 250, 251, 1);
$gray25: rgba(252, 252, 253, 1);

// Blues
$blue25: rgb(245, 251, 255);
$blue50: rgba(240, 249, 255, 1);
$blue100: rgba(224, 242, 254, 1);
$blue700: rgba(2, 106, 162, 1);
$blue900: rgba(11, 74, 111, 1);
$blueLight50: #f0f9ff;
$blueLight700: #026aa2;
$blueDark900: rgba(41, 48, 86, 1);

// Yellows
$yellow50: #fefbe8;

// PRIMARY SHADES
$primary900: #1c465e;
$primary800: #274f66;
$primary700: rgba(59, 101, 125, 1);
$primary600: rgba(72, 110, 131, 1);
$primary500: #5b8094;
$primary400: #7398ab;
$primary300: #8dadbe;
$primary200: #c2d2db;
$primary100: #e0eaf1;
$primary50: rgba(241, 245, 248, 1);
$primary25: #fafcfd;

// TODO: replace once fully migrated * Colors */
$primary: #fff;
$secondary: #486e83;
$secondary05: rgba(72, 110, 131, 0.05);
$secondary10: rgba(72, 110, 131, 0.1);
$secondary20: rgba(72, 110, 131, 0.2);
$secondary30: rgba(72, 110, 131, 0.3);
$secondary50: rgba(72, 110, 131, 0.5);

// $tertiary: rgb(254, 101, 79);
$tertiary: #4e0250;
$tertiary50: rgb(78, 2, 80, 0.5);
$tertiary20: rgb(78, 2, 80, 0.2);
$tertiary05: rgb(78, 2, 80, 0.05);

$charcoal: #343036;
$white: #fff;
$background: rgba(245, 243, 241, 1);
$gradientLightBl: linear-gradient(90deg, rgba(239, 248, 255, 1) 0%, white 50%);

/* Distilled Palette */
$darkBlue: rgba(72, 110, 131, 1);
$lightBlue: rgba(222, 232, 237, 1);
$highlightBlue: rgba(239, 248, 255, 1);
$highlightTextBlue: rgba(47, 128, 237, 1);
$iconBlue: rgba(209, 233, 255, 1);

$darkGrey: rgba(16, 24, 40, 1);
$medGrey: rgba(52, 64, 84, 1);
$lightGrey: rgba(102, 112, 133, 1);
$placeholderGrey: rgba(239, 239, 239, 0.5);
$placeholderTextGrey: rgba(140, 140, 140, 1);
$highlightGrey: rgba(239, 239, 239, 1);
$inputGrey: rgba(249, 250, 251, 1);
$headerGrey: rgba(252, 252, 253, 1);
$borderGrey: #d0d5dd;

// interaction colors
$success: rgba(209, 250, 223, 1);
$success25: rgba(246, 254, 249, 1);
$success50: rgb(224, 252, 234);
$successText: rgba(2, 122, 72, 1);
$successBorder: rgba(18, 183, 106, 1);

$error: rgba(254, 228, 226, 1);
$error25: rgba(255, 251, 250, 1);
$error50: #fef3f2;
$error200: rgba(254, 205, 202, 1);
$error300: rgb(253, 162, 155);
$error400: rgba(249, 112, 102, 1);
$errorText: rgba(180, 35, 24, 1); // error700
$errorBorder: rgba(240, 68, 56, 1);

$info: rgba(243, 252, 254, 1);
$infoText: rgba(1, 124, 154, 1);
$infoBorder: rgba(1, 124, 154, 1);

// UI Colors (from Charting Experience)
$infoTag: #eff8ff;
$infoTagText: rgba(23, 92, 211, 1);

$actionBtnBl: rgba(46, 144, 250, 1);
$iconBl: rgb(25, 65, 133);

$warning: rgba(254, 240, 199, 1);
$warning25: rgba(255, 252, 245, 1);
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning200: #fedf89;
$warning300: #fec84b;
$warning700: #b54708;
$warningText: rgba(181, 71, 8, 1);
$warningBorder: rgb(254, 200, 75);

// calendar colors
$calendarOne: #717bbc;
$calendarTwo: #b9c0d4;
$calendarThree: #a9a29d;
$calendarFour: #acdc79;
$calendarFive: #d0f8ab;
$calendarSix: #aaf0c4;
$calendarSeven: #99f6e0;
$calendarEight: #a5f0fc;
$calendarNine: #b9e6fe;
$calendarTen: #84caff;
$calendarEleven: #84adff;
$calendarTwelve: #c7d7fe;
$calendarThirteen: #c3b5fd;
$calendarFourteen: #d9d6fe;
$calendarFifteen: #eeaafd;
$calendarSixteen: #faa7e0;
$calendarSeventeen: #fea3b4;
$calendarEighteen: #ffd6ae;
$calendarNineteen: #f7b27a;
$calendarTwenty: #feee95;

// Copilot colors
$copilotPrimary: #fea3b4;

// Encounter statuses
$encounterDefault: #364152;
$encounterDefaultLight: #eef2f6;
$encounterInProgress: #b54708;
$encounterInProgressLight: #fef0c7;
$encounterSubmitted: #027a48;
$encounterSubmittedLight: #d1fadf;
$encounterDeleted: $errorText;
$encounterDeletedLight: $error200;

/* Typography */

// font sizing
$fontSmallest: 10px;
$fontSmall: 12px;
$fontBase: 14px;
$fontMd: 18px;
$fontLg: 24px;
$fontXL: 30px;
$fontDisplay: 52px;
$fontGraphic: 76px;

// font weight
$fontReg: 400;
$fontSemibold: 500;
$fontBold: 700;
$fontBolder: 800;

/* Spacing */
$spaceSm: 10px;
$spaceMd: 20px;
$spaceLg: 30px;
$spaceXL: 40px;
$padding: 16px;

/* Translations */
$headerHeight: 100px;
$footerHeight: 71px;

/* Box Shadow */
$shadowXs: 0px 1px 2px rgba(16, 24, 40, 0.05);
$shadowSm: 0px 1px 4px rgba(16, 24, 40, 0.05);
$shadowMd:
  0px 4px 8px -2px rgba(16, 24, 40, 0.1),
  0px 2px 4px -2px rgba(16, 24, 40, 0.06);
$shadowLg:
  0px 4px 6px -2px rgba(16, 24, 40, 0.03),
  0px 12px 16px -4px rgba(16, 24, 40, 0.08);

/* Border */
$borderRadius: 8px;
$gridBorder: 1px solid $grey200;

@mixin roundTop {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
}

@mixin roundBottom {
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

/* Translations */
$headerHeight: 100px;
$footerHeight: 71px;
$sidebarWidth: 80px;

/* Mixins */

/* Typography */
@mixin textXsReg {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

@mixin textXsMedium {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@mixin textSmMedium {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin textLgSemibold {
  font-weight: 600;
  size: 18px;
  line-height: 28px;
}

// display

@mixin flex {
  display: flex;
  width: 100%;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin center {
  align-items: center;
  justify-content: center;
}

@mixin colWidth {
  max-width: $laptop;
}

/* Reused Styles */
@mixin lip {
  @include flex;
  align-items: center;
  background-color: $headerGrey;
  color: $lightGrey;
  border-radius: 5px 5px 0 0;
  font-size: $fontSmall;
  border-bottom: $gridBorder;
}

@mixin visuallyHidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@mixin card {
  border-radius: $borderRadius;
  color: $medGrey;
  background: $white;
  border: $gridBorder;
}

@mixin header {
  @include roundTop;
  @include flex;
  align-items: center;
  justify-content: space-between;
  padding: $padding/2 $padding;
}

@mixin blueHeader {
  @include header;
  background-color: $primary200;
}

@mixin highlight {
  color: $highlightTextBlue;
  background-color: $highlightBlue;
  border-bottom: 2px solid $highlightTextBlue;
}

@mixin spinner {
  margin-top: 2px;
  border: 2px solid $infoText;
  border-left-color: $lightBlue;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}

/* Animations */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes leave {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

:export {
  error50: $error50;
  error: $error;
  errorText: $errorText;
  success: $success;
  successText: $successText;
  success50: $success50;
  warning: $warning;
  warningText: $warningText;
  warning100: $warning100;
  warning200: $warning200;
  info: $infoText;
  blue100: $blue100;
  blue700: $blue700;
  gray25: $gray25;
  gray50: $gray50;
  gray100: $gray100;
  gray200: $grey200;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  gray600: $gray600;
  gray700: $gray700;
  gray800: $gray800;
  gray900: $gray900;
  primary25: $primary25;
  primary50: $primary50;
  primary100: $primary100;
  primary200: $primary200;
  primary300: $primary300;
  primary700: $primary700;
  primary600: $primary600;
  primary500: $primary500;
  primary400: $primary400;
  primary800: $primary800;
  primary900: $primary900;
  errorText: $errorText;
  calendarOne: $calendarOne;
  calendarTwo: $calendarTwo;
  calendarThree: $calendarThree;
  calendarFour: $calendarFour;
  calendarFive: $calendarFive;
  calendarSix: $calendarSix;
  calendarSeven: $calendarSeven;
  calendarEight: $calendarEight;
  calendarNine: $calendarNine;
  calendarTen: $calendarTen;
  calendarElevent: $calendarEleven;
  calendarTwelve: $calendarTwelve;
  calendarThirteen: $calendarThirteen;
  calendarFourteen: $calendarFourteen;
  calendarFifteen: $calendarFifteen;
  calendarSixteen: $calendarSixteen;
  calendarSeventeen: $calendarSeventeen;
  calendarEighteen: $calendarEighteen;
  calendarNineteen: $calendarNineteen;
  calendarTwenty: $calendarTwenty;
  blueIcon: #0086c9;
  medGrey: $medGrey;
  warning300: $warning300;
}
