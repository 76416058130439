@import "@/styles/variables.scss";
// Login card styles
.Login {
  margin-top: $headerHeight;
  @include flexCol;
  @include center;
  // login form card
  .card {
    @include flexCol;
    @include card;
    min-width: 300px;
    width: 40%;
    padding: $padding * 1.5;
    text-align: center;
  }

  .icon {
    margin: $spaceSm 0;
    &:before {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      transform: translate(-5px, -4px);
      background-color: $blue700;
      border-radius: 50%;
      opacity: 10%;
    }
    &:after {
      content: "";
      position: absolute;
      width: 35px;
      height: 35px;
      transform: translate(-25px, -9px);
      background-color: $blue700;
      border-radius: 50%;
      opacity: 20%;
    }
  }
  // login form
  form {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: $padding;
    gap: $padding;
    text-align: left;

    div {
        place-self: center;
        max-width: unset;
        width: 66%;
    }

    // form buttons
    .buttons {
      @include flex;
      align-items: center;
      justify-content: center;
      gap: $spaceMd;
    }

    // forgot password link
    .forgot {
      font-size: $fontSmall;
      width: 100%;
      margin-bottom: $spaceSm;
    }
  }
}

.ErrorMessage {
    margin-top: $spaceMd;
    padding: $spaceSm;
    max-width: 75%;
    align-self: center;

    border-color: $errorBorder;
    color: $errorText;
    background-color: $error;
}

.centered {
    @include flex;
    @include center;
}
