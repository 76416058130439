@import "./variables.scss";

//* Pario Internal Page Styles *//
.Page {
  &.LeftPaneOpen {
    margin-top: $spaceMd;
    padding-left: 295px;
  }
  scroll-behavior: smooth;
  min-height: calc(100vh);
  background-color: $white;
  position: relative;

  .headerWrapper {
    &.horizontal {
      @include flex;
      align-items: center;
      gap: $padding / 2;
    }
    padding: 1.5 * $padding;
    padding-bottom: 0;
    color: $gray900;

    @media (max-width: $tablet) {
      padding: $padding;
      h1 {
        margin-bottom: 0;
      }
    }

    h1 {
      margin-bottom: $padding / 2;
    }

    .wrap {
      @include flex;
      justify-content: space-between;
      gap: $padding;

      .right {
        display: flex;
        gap: $padding;
      }
    }

    .subHeader {
      display: flex;
      align-items: center;
      gap: $padding;

      .notifPills {
        display: flex;
        gap: $padding;
      }
    }
  }

  .divide {
    height: 2px;
    padding: 6px;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    width: 100%;

    .wrap {
      @include flex;
      align-items: flex-start;
      justify-content: space-between;
      h1 {
        flex-shrink: 0;
      }
    }
  }

  .mainContent {
    min-height: 100vh;
    width: 100%;
  }

  .backButton {
    width: 40px;
    height: 40px;
    font-size: $fontMd;
    position: fixed;
    border-radius: 50%;
    bottom: 30px;
    left: 20px;
    background-color: $blue100;
    color: $primary700;
    font-weight: $fontSemibold;
  }

  .contentWrapper {
    padding: $padding;
  }
}

.MobileInboxPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  min-height: calc(100vh - 48px);

  .inboxContainer {
    flex: 1;
  }
}

.banner {
  align-items: center;
  background-color: $gray25;
  border: 1px solid $gray300;
  padding: $padding;
  border-radius: $borderRadius;
  margin-bottom: $padding;

  &.warningBanner {
    display: flex;
    align-items: flex-start;
    gap: $padding/2;
    color: $warningText;
    background-color: $warning50;
    .iconWrapper {
      margin-top: 2px;
    }
  }
}
